import { module } from 'modujs';
import lottieWeb from 'lottie-web';
export default class extends module {
    constructor(m) {
        super(m);
    }
    init() {
      // var json = this.getData('json');
      // var animation = lottieWeb.loadAnimation({
      //   container: document.getElementById('lottie'),
      //   path: json,
      //   renderer: 'svg',
      //   loop: false,
      //   autoplay: true,
      //   name: "Entree Animation",
      // });
    }
}