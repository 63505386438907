import { module } from 'modujs';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
          click: {
            dots: 'toggleDots',
          }
        }
    }

    init() {
      var items = 1;
      var pagination = false;
      var navigation = false;
      var breakpoints = [];
      var autoplay = false;
      var direction = 'horizontal';
      var spaceBetween = 0;
      var slideChange = false;
      var touch = true;
      var autoHeight = false;

      if(this.getData('autoHeight')) {
        autoHeight = true;
      }

      if(this.getData('touch')) {
        touch = false;
      }

      if (this.getData('autoplay')) {
        autoplay = {
          delay: this.getData('autoplay'),
          disableOnInteraction: false,
        };
      }

      if (this.getData('items')) {
        items = parseInt(this.getData('items'));
      }

      if (this.getData('itemsAuto')) {
        items = this.getData('itemsAuto');
      }

      if(this.getData('spaceBetween')) {
        spaceBetween = this.getData('spaceBetween');
      }

      if (this.getData('pagination')) {
        pagination = {
          el: this.getData('pagination'),
          clickable: true,
        };
      }

      if (this.getData('navigation')) {
        navigation = {
          nextEl: this.getData('navigation') + '-next',
          prevEl: this.getData('navigation') + '-prev',
          disabledClass: '-disabled',
        };
      }

      if (this.getData('breakpoints')) {
        breakpoints = JSON.parse(this.getData('breakpoints'));
      }

      if (this.getData('direction')) {
        direction = this.getData('direction');
      }

      if(this.getData('counter')) {
        let counter = this.getData('counter');
        let total = 0;

        if(breakpoints) {
          for (let x in breakpoints) {
            if($(document).outerWidth() >= x) {
              total = breakpoints[x].slidesPerView - 1;
            }
          }
        }

        slideChange = function(swiper) {
          var $total = (swiper.slides.length - total);
          if($total < 0) {
            $total = 1;
          }
          var index = (swiper.activeIndex + 1) + ' - ' + $total;
          $(counter).text(index);
        };
      }

      new Swiper(this.el, {
        autoplay: autoplay,
        slidesPerView: items,
        spaceBetween: parseInt(spaceBetween),
        // Optional parameters
        direction: direction,
        //loop: true,
        autoHeight: autoHeight,

        // If we need pagination
        pagination: pagination,

        // Navigation arrows
        navigation: navigation,

        runCallbacksOnInit: true,
        on: {
          init: slideChange,
          realIndexChange: slideChange,
        },
        breakpoints: breakpoints,

        allowTouchMove: touch,
      });

    }

    toggleDots(e){
      let dot = parseInt(e.currentTarget.id.split('dots-')[1]) * 100;
      let images = document.getElementsByClassName('b-accueil_img');

      for(let i = 0; i < images.length; i++){
        images[i].style.transform = `translateX(-${dot}%)`;  
      }
    }
}
