import { module } from 'modujs';

export default class extends module {
  constructor(m) {
      super(m);

      this.events = {
        click: {
          tiroir: 'toggleSection',
          onglet: 'toggleOnglet',
          timeline: 'toggleTimeline',
          header: 'toggleHeader',
          ongletIntro: 'toggleOngletIntro',
          close: 'toggleClose',
        }
      }
  }

  init() {
    if (this.getData('open')) {
      this.$('section')[0].classList.add('is-open');
    }
    if (document.getElementById('0')){
      document.getElementById('0').classList.add('i-clicked');
    }
  }

  toggleSection(e) {
    const target = e.currentTarget;
    const section = this.parent('section', target);
    if (section.classList.contains('is-open')) {
      section.classList.remove('is-open');
    } else {
      const sections = this.$('section');
      sections.forEach(element => element.classList.remove('is-open')); // close section opened
      section.classList.add('is-open');
      if(this.getData('scrollto') == 'true') {
        this.call('scrollTo', section, 'Scroll'); // scrollTo section
      }
    }
    this.call('update', false, 'Scroll'); // update Locomotive Scroll
  }

  toggleOnglet(e){
    let text = document.getElementsByClassName('b-onglet_text');
    let id = Number(e.target.id.split('-')[1]) * -100;

    for (let i = 0; i < text.length; i++){
      text[i].style.transform= `translateX(${id}%)`;
      if (document.getElementsByClassName('clicked')[0]) document.getElementsByClassName('clicked')[0].classList.remove('clicked');
      e.target.classList.add('clicked')
    }
  }

  toggleOngletIntro(e){
    let text = document.getElementsByClassName('b-intro_content_component');
    let id = Number(e.target.id) * -100;

    for (let i = 0; i < text.length; i++){
      text[i].style.transform= `translateX(${id}%)`;
      if (document.getElementsByClassName('i-clicked')[0]) document.getElementsByClassName('i-clicked')[0].classList.remove('i-clicked');
      e.target.classList.add('i-clicked')
    }
  }

  toggleTimeline(e){
    let content = e.target.parentNode.parentNode;

    if(document.getElementsByClassName('t-clicked').length === 1){
      document.getElementsByClassName('t-clicked')[0].classList.remove('t-clicked');
    }

    if(content.classList.contains('t-clicked')){
      content.classList.remove('t-clicked');
    } else{
      content.classList.add('t-clicked');
    }
  }

  toggleHeader(e){
    if(e.target.parentNode.classList.contains('h-clicked')){
      document.getElementsByClassName('h-clicked')[0].classList.remove('h-clicked');
    } else{
      if(document.getElementsByClassName('h-clicked').length > 0) document.getElementsByClassName('h-clicked')[0].classList.remove('h-clicked');
      e.target.parentNode.classList.add('h-clicked');
    }
  }

  toggleClose(e){
    if (e.target.parentNode.classList.contains('c-header_flash')){
      e.target.parentNode.classList.add('h-closed');
    } else{
      e.target.parentNode.parentNode.classList.add('h-closed');
    }
  }
}
