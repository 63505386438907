/**
 * External Dependencies
 */
import 'jquery';

import './locomotive/app';

$(() => {
  $('.cff-post-links a').append("<span class='line'></span><span class='dot'></span>");
});
