import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
      this.scroll = new LocomotiveScroll({
        el: this.el,
        smooth: false,
        reloadOnContextChange: true,
        touchMultiplier: 4,
        tablet: {
          smooth: false,
        },
        smartphone: {
          smooth: false,
        }
      });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        $('[data-scroll-call="animateNumber"]').each(function(){
          if($(this).hasClass('is-inview')) {
            $(this).prop('Counter',0).animate({
              Counter: $(this).data('number'),
            }, {
              duration: 2000,
              easing: 'swing',
              step: function (now) {
                $(this).text(Math.ceil(now).toLocaleString('cs-CZ'));
              },
            });
          }
        });

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
            
            if(func == 'animateNumber') {
              $(obj).prop('Counter',0).animate({
                Counter: $(obj.el).data('number'),
              }, {
                duration: 2000,
                easing: 'swing',
                step: function (now) {
                  $(obj.el).text(Math.ceil(now).toLocaleString('cs-CZ'));
                },
              });
            }
        });

        this.scroll.on('scroll', (args) => {
          if(args.scroll.y >= 550 && document.getElementById('projet')){
            document.getElementById('projet').classList.add('-scrolling');
          }

          if(args.scroll.y < 550 && document.getElementById('projet')){
            document.getElementById('projet').classList.remove('-scrolling');
          }
      })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target);
    }

    update() {
      this.scroll.update();
    }

}
