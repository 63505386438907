import { module } from 'modujs';
import Plyr from 'plyr';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                // play: 'play',
            }
        }

        this.player = null;
    }

    init() {
        this.player = new Plyr(this.el);
    }

    initModal(target) {
        console.log('here', target);
        this.player = new Plyr(target);
    }

    // play(e) {
    //     const target = e.currentTarget;
    //     const type = this.getData('type');
    //     $(target).addClass('is-hidden');

    //     if(type == 'embed') {
    //         $(this.el).find('iframe')[0].src += "&amp;autoplay=1";
    //     }else{
    //         $(this.el).find('video')[0].play();
    //     }
    // }
}
